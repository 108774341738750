import * as React from "react"

const about = () => (
  <div className="grid grid-nogutter surface-0 text-800 px-custom py-head-custom">
     <div className="col-12 text-center flex align-items-center">
      <section>
        <span className="block text-3xl lg:text-5xl font-bold">
          Welcome to Tronstride
        </span>
        <p className="mt-0 mb-4 text-700 text-xl line-height-3 mt-4">
          We offer a full range of accounting, Canadian tax services to business
          and personal clients.
        </p>
        <p className="mt-0 mb-4 text-700 text-xl line-height-3">
          We provide highest level of personalized service possible, our clients
          benefit directly from our extensive experience and focus on practical.
        </p>
        <p className="mt-0 mb-4 text-700 text-xl line-height-3">
          Your need for Accountant to attain Strategic Guidance, insight to help
          start or grow your business, for building wealth while minimizing
          losses to taxation, if you need personal tax planning.
        </p>
        <p className="mt-0 mb-4 text-700 text-xl line-height-3">
          We study, analyze and identify the weakness in your business to
          maximize your profits and minimize taxes.
        </p>
         </section>
    </div>
  </div>
)

export default about
