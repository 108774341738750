import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../container/about"
import Features from "../container/features"
import AboutData from "../Data/about.json"

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    <About />
    <Features data={AboutData} heading="Why Choose Us" />
  </Layout>
)

export default AboutPage
